<template>
  <v-dialog v-model="dialog" v-shortkey="['ctrl', 'shift', 'l']" max-width="400px" @shortkey.native="triggerDialog">
    <v-card>
      <v-card-title>
        <h3 class="title">Login Admin</h3>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <span class="subtitle"
              >Os logins admins são exclusivamente feitos através de e-mails com o dominio <strong>mercafacil.com</strong> e precisam ser pré-aprovados</span
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <google-signin-button class="d-flex justify-center" :source="source" @adminLogin="adminLogin" @toggleDialog="(state) => (dialog = state)" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { enums } from '@mf-node/mf-vue-components'

import GoogleSigninButton from './GoogleSigninButton.vue'

export default {
  name: 'InternalLogin',
  components: {
    GoogleSigninButton,
  },
  props: {
    value: {
      type: Boolean,
    },
    source: {
      type: String,
      required: true,
      validator: enums.authSources.getAuthSource,
    },
    adminLogin: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    loading: false,
  }),
  watch: {
    dialog(value) {
      if (value) {
        this.loading = false
      } else {
        this.$emit('input', false)
      }
    },
    value(value) {
      if (value) {
        this.dialog = true
      }
    },
  },
  methods: {
    triggerDialog() {
      this.dialog = true
    },
  },
}
</script>
